import React, { Fragment, useEffect, useState, useContext } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, Tabs, message, Popover } from 'antd'
import type { TabsProps } from 'antd'
import Datatopbox from './Components/Datatopbox'
import UploadModel from '@/components/UploadModel'
import { downloadFile } from '@/utils/utils'
import ThreedPart from './Components/ThreedPart'
import Lookthreed from './Components/Lookthreed'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import threedApi from '@/api/threedApi'
import { useLocation } from 'react-router-dom'



const Wholevehicle: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { store } = useContext(context)
  const { brandsList, brandData, writeAuth } = store
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)
  const [threedOpen, setThreedOpen] = useState<boolean>(false)
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [ispreview, setIspreview] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  const [currencyData, setCurrencyData] = useState<any>({models: {}})
  
  
  const [searchData, setSearchData] = useState<any>({
    brandCode: '',
    series: '',
    isrelationModels: ''
  })

  const [activeKey, setActiveKey] = useState<'model' | 'part'>("model")

  const [seriesList, setSeriesList] = useState<any>([])

  useEffect(() => {
    if(currencyOpen) {
      let params = {
        brandCode: 'normal',
        series: 'normal'
      }
      threedApi.getWholeList(params).then((res: any) => {
        if(res.code === 1) {
          setCurrencyData(res.data.records && res.data.records[0] ? res.data.records[0] : {models: {}})
        }
      })
    }
  },[currencyOpen])
  
  useEffect(() => {
    if(searchData.brandCode || activeItem.brandCode) {
      getSeriesList()
    }
  },[searchData.brandCode, activeItem.brandCode])

  useEffect(() => {
    getList()
  },[searchData.brandCode, searchData.series, searchData.isrelationModels, pager, pageSize])

  const getSeriesList = () => {
    let newParams = {
      attribute: 'models',
      keyword: {brand: [searchData.brand || activeItem.brand]}
    }
    dataApi.getBrandsList(newParams).then((res: any) => {
      if(res.code === 1) {
        let _data = res.data.map((item: any) => {
          return {
            label: item,
            value: item
          }
        })
        setSeriesList(_data)
      }
    })
  }

  const getList = () => {
    let params = {
      brandCode: searchData.brandCode,
      series: searchData.series,
      page: pager.page,
      size: pageSize
    }
    setLoading(true)
    threedApi.getWholeList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        setList(res.data.records ? res.data.records : [])
      }
    })

  }

  const editItem = (item: any) => {
    setActiveItem({...item})
    setOpen(true)
  }
  const closeOpen = () => {
    setActiveItem({})
    setIspreview(false)
    setOpen(false)
  }

  const preview = (item: any) => {
    setActiveItem({...item})
    setThreedOpen(true)
  }
  const downLoad = (item: any) => {
    downloadFile(item.url)
  }

  const submit = async (type?: string) => {
    let params: any = {}
    if(type) {
      params = {...currencyData}
    }else {
      params = {...activeItem}
      if(!(params.brandCode && params.series && params.models && (params.models.android || params.models.ios))) {
        message.warning("请填写必填项！")
        return false
      }
    }
    if(params.id) {
      params.mongo_id = params.id
      delete params.id
    }else if(params.series && params.series.split(",").length > 1) {
      // 新增（可批量）
      let seriesArr = params.series.split(",")
      setSubmitLoading(true)
      let num: number = 0 
      for(let i = 0; i < seriesArr.length; i++) {
        params.series = seriesArr[i]
        let res = await threedApi.operateWholeItem(params)
        if(res.code === 1) {
          num++
        }
      }
      setSubmitLoading(false)
      if(num === seriesArr.length) {
        closeOpen()
        message.success("操作成功")
        getList()
      }else {
        message.warning("添加失败")
      }

      return false
    }
    setSubmitLoading(true)
    threedApi.operateWholeItem(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setCurrencyOpen(false)
        closeOpen()
        message.success("操作成功")
        getList()
      }
    })
    
  }

  const submitCurrency = () => {
    submit("currency")
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '品牌',
      dataIndex: 'brandCode',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            <div className={'brand-logo mr5'}>
              <img className="img" src={brandData[text]?.logo} />
            </div>
            {brandData[text]?.name}
          </div>
        )
      }
    },
    {
      title: '车系',
      dataIndex: 'series',
      align: 'center'
    },
    // {
    //   title: '已关联车型',
    //   dataIndex: 'id',
    //   align: 'center'
    // },
    // {
    //   title: '示例车架号',
    //   dataIndex: 'vin',
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => {
    //     return (
    //       <div className="flex-c-c p-color">
    //         {text}
    //       </div>
    //     )
    //   }
    // },
    // {
    //   title: '大小 (MB)',
    //   dataIndex: 'id',
    //   align: 'center'
    // },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 220,
      render: (text: any, record: any, index: number) => {
        if(!writeAuth[pathname]) return null
        let models = record.models ? record.models : {}
        let notclick = !models.android || (models.android && !models.android.includes('.gltf')) ? true : false
        return (
          <div className="flex-c-c">
            <div className={"flex-s-c p-color pointer" + (notclick ? " no-click" : '')} onClick={() => !notclick && preview(record)}>
              <i className="iconfont icon-yulan fs14 mr2" />
              预览
            </div>
            {
              models.ios || models.android ? (
                <Popover
                  content={
                    <div>
                      {
                        models['ios'] ? (
                          <div className='pointer ptb5' onClick={() => downLoad(models['ios'])}>ios</div>
                        ): null
                      }
                      {
                        models['android'] ? (
                          <div className='pointer ptb5' onClick={() => downLoad(models['android'])}>android</div>
                        ): null
                      }
                    </div>
                  }
                >
                  <div className="flex-s-c p-color pointer mlr15">
                    <i className="iconfont icon-xiazai fs14 mr2" />
                    下载
                  </div>
                </Popover>
              ): (
                <div className="flex-s-c p-color pointer mlr15 no-click" >
                  <i className="iconfont icon-xiazai fs14 mr2" />
                  下载
                </div>
              )
            }
            <div className="flex-s-c p-color pointer" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              修改
            </div>
          </div>
          
        )
      }
    },
  ]

  const items: TabsProps['items'] = [
    {
      key: 'model',
      label: '模型',
      children: (
        <Fragment>
          <div className="flex-b-c">
            <div className='flex-s-c'>
              <span>筛选：</span>
              <Select
                className="mr12"
                style={{ width: 200 }}
                showSearch
                filterOption={(input: any, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={brandsList}
                placeholder="品牌"
                allowClear
                value={searchData.brandCode || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, brandCode: value || '', series: ''}) }}
              />
              <Select
                className="mr12"
                style={{ width: 200 }}
                placeholder="车系"
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={searchData.brandCode ? seriesList : []}
                value={searchData.series || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, series: value}) }}
              />
              {/* <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="是否关联车型"
                value={searchData.isrelationModels || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, isrelationModels: value}) }}
              /> */}
              
            </div>
            {
              writeAuth[pathname] ? (
                <div>
                  <Button onClick={() => { setOpen(true); setActiveItem({models: {}}) }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />}>添加模型</Button>
                </div>
              ): null
            }
            
          </div>
          <div className="mt10">
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                current: pager.page,
                total: pager.total,
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current: any, size: any) => setPageSize(size),
                onChange: (page: any) => setPager({...pager, page}),
                showTotal: (total: number) => `共 ${total} 条`
              }}
            />
          </div>
        </Fragment>
      )
    },
    // {
    //   key: 'part',
    //   label: '零件',
    //   children: <ThreedPart/>
    // },
  ]
  return (
    <div >
      <Datatopbox

      />
      <div className="mt8 bgc-fff plr15 pb10 br4" style={{ minHeight: 'calc(100vh - 140px)' }}>
        <Tabs
          items={items}
          activeKey={activeKey}
          onChange={(value: any) => setActiveKey(value)}
        />
      </div>

      <Modal
        title={activeItem.id ? '修改模型' : "添加模型"}
        open={open}
        width={620}
        onCancel={() => closeOpen()}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
        maskClosable={false}
      >
        <div className="mtb20">
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>品牌：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择品牌"
              size="large"
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={brandsList}
              value={activeItem.brandCode}
              onChange={
                (value: any) => {
                  setActiveItem({
                    ...activeItem,
                    series: '',
                    brandCode: value
                  })
                }
              }
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>车系：</label>
            {
              activeItem.id ? (
                <Select
                  style={{ flex: 1 }}
                  placeholder="选择车系"
                  size="large"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={activeItem.brandCode ? seriesList : []}
                  value={activeItem.series}
                  onChange={(value: any) => {
                    setActiveItem({...activeItem, series: value})
                  }}
                />
              ): (
                <Select
                  mode="tags"
                  style={{ flex: 1 }}
                  placeholder="选择车系"
                  size="large"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={activeItem.brandCode ? seriesList : []}
                  value={activeItem.series ? activeItem.series.split(",") : undefined}
                  onChange={(value: any) => {
                    setActiveItem({...activeItem, series: value ? value.join(",") : ''})
                  }}
                />
              )
            }
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>模型：</label>
            <div className='flex-1 flex-b-c'>
              <UploadModel
                item={activeItem.models}
                lkey="ios"
                uploadLabel="上传IOS文件(加密zip)"
              />
              <UploadModel
                item={activeItem.models}
                lkey="android"
                uploadLabel="上传安卓文件(Gltf)"
              />
            </div>
          </div>
        </div>
      </Modal>

      


      {
        threedOpen ? (
          <Lookthreed
            title='预览'
            open={threedOpen}
            setOpen={(value: any) => setThreedOpen(value)}
            file={activeItem.models && activeItem.models.android ? activeItem.models.android : "https://bigfiles.007vin.com/3d/07114_Web_v2.gltf"}
          />
        ): null
      }
    </div>
  )
}

export default Wholevehicle