import axios from 'axios'
import qs from 'qs'

const vinApi: any = {}

vinApi.vin = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/vin', { params })
}
vinApi.group = (params: {}): Promise<{}>=>{
  return axios.get('/api/cars/group', { params })
}
vinApi.structure = (params: {}): Promise<{}>=>{
  return axios.get('/api/cars/structure', { params })
}
vinApi.subGroup = (params: {}): Promise<{}>=>{
  return axios.get('/api/cars/structure/subs', { params })
}
vinApi.config = (params: {}): Promise<{}>=>{
  return axios.get('/api/cars/config/search', { params })
}
vinApi.points = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/structimgs/points", {params})
}
vinApi.partnewvin = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/vin/part/ai", {params})
}
vinApi.partnew = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/part/ai", {params})
}

vinApi.part = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/part", {params})
}

vinApi.config = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/config", {params})
}
vinApi.configSearch = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/config/search", {params})
}
vinApi.tag = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/part/tags", {params})
}
vinApi.getStandardLabel = (params: {}): Promise<{}> => {
  return axios.get("/api/engine/search/stdname_remake", {params})
}

vinApi.baseinfo = (params: {}): Promise<{}> => {
  return axios.get("/api/parts/baseinfo", {params})
}
vinApi.replace = (params: {}): Promise<{}> => {
  return axios.get("/api/parts/replace", {params})
}
vinApi.articles = (params: {}): Promise<{}> => {
  return axios.get("/api/parts/articles", {params})
}
vinApi.applicable = (params: {}): Promise<{}> => {
  return axios.get("/api/parts/generacars", {params})
}
vinApi.pics = (params: {}): Promise<{}> => {
  return axios.get("/api/user/ugc/part/pics", {params})
}
vinApi.removePic = (params: {}): Promise<{}> => {
  return axios.get("/api/user/ugc/part/picdel", {params})
}

vinApi.getMaintenancePoint = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/maintenance/sub/group/struct", {params})
}
vinApi.getMaintenancePart = (params: {}): Promise<{}> => {
  return axios.get("/api/cars/maintenance/sub/group/parts", {params})
}

vinApi.searchKey = (params: {}): Promise<{}> => {
  return axios.get("/api/engine/parts/search_key_v2", {params})
}

vinApi.searchHot = (params: {}): Promise<{}> => {
  return axios.get("/api/engine/parts/search_name_v2", {params})
}

vinApi.partSearch = (params: {}): Promise<{}> => {
  return axios.get("/api/engine/parts/search", {params})
}
vinApi.partPrice = (params: {}): Promise<{}> => {
  return axios.get("/api/parts/price", {params})
}

vinApi.ocr = (params: {}): Promise<{}> => {
  return axios.post("/api/ocr/parse", params)
}

vinApi.getBrand = (params: {}): Promise<{}> => {
  return axios.get("/api/brands", {params})
}
vinApi.getTitle = (params: {}) => {
  return axios.get('/api/cars/brand/title', {params})
}
vinApi.getModels = (params: {}, cancelToken: any): Promise<{}> => {
  return axios.get("/api/cars/models", {...{params}, ...cancelToken})
}

vinApi.getCornetBrand = (params: {}) => {
  return axios.get('/api/title/brands', {params})
}


vinApi.ocrPath = '/api/ocr/parse'

export default vinApi