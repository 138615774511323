import React, { useEffect, useState, useContext } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, Popconfirm, message } from 'antd'
import Datatopbox from '../Datatopbox'
import UploadModel from '@/components/UploadModel'
import { downloadFile } from '@/utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import threedApi from '@/api/threedApi'
import context from '@/context/MainContext'



const ThreedPart: React.FC<any> = (props) => {
  const { } = props
  const { pathname } = useLocation()
  const { store } = useContext(context)
  const { brandsList, brandData, writeAuth } = store
  const navigate = useNavigate()
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  const [mainGroupOptions, setMainGroupOptions] = useState<any>([])
  const [subGroupOptions, setSubGroupOptions] = useState<any>([])

  const [otherSubGroup, setOtherSubGroup] = useState<any>([])
  
  
  const [searchData, setSearchData] = useState<any>({
    standard_maingroup_id: '',
    standard_subgroup_name_id: '',
    keyword: ''
  })
  
  useEffect(() => {
    getMainGroup()
  },[])

  useEffect(() => {
    if(searchData.standard_maingroup_id || activeItem.standard_maingroup_id) {
      getSubGroup()
    }
  },[searchData.standard_maingroup_id, activeItem.standard_maingroup_id])

  

  useEffect(() => {
    getList()
  },[searchData.standard_maingroup_id, searchData.standard_subgroup_name_id, searchData.keyword, pager, pageSize])

  const getMainGroup = () => {
    threedApi.getMainGroup().then((res: any) => {
      if(res.code === 1) {
        let _data = (res.data.records || []).map((item: any) => {
          return {
            label: item.standard_maingroup_name,
            value: item.standard_maingroup_id
          }
        })
        setMainGroupOptions(_data)
      }
    })
  }
  const getSubGroup = (type?: string) => {
    let params = {
      standard_maingroup_id: open ? activeItem.standard_maingroup_id : searchData.standard_maingroup_id 
    }
    threedApi.getSubGroup(params).then((res: any) => {
      if(res.code === 1) {
        let _data: any = [];
        (res.data.records || []).map((item: any) => {
          let i: number = _data.findIndex((it: any) => it.value === item.standard_subgroup_name_id)
          if(i === -1) {
            _data.push({
              label: item.standard_subgroup_name,
              value: item.standard_subgroup_name_id
            })
          }
        });
        if(open) {
          setOtherSubGroup(_data)
        }else {
          setSubGroupOptions(_data)
        }
      }
    })
  }

  const getList = () => {
    let params = {
      ...searchData,
      search_key: searchData.keyword,
      page: pager.page,
      size: pageSize
    }
    delete params.keyword
    setLoading(true)
    threedApi.getPartList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        setList(res.data.records ? res.data.records : [])
      }
    })
  }

  const editItem = (item: any) => {
    setActiveItem({...item})
    setOpen(true)
  }
  

  const submit = () => {
    let params: any = {
      ...activeItem
    }
    if(params.id) {
      params.pid_id = params.id
    }
    setSubmitLoading(true)
    threedApi.updatePart(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setOpen(false)
        message.success("操作成功")
        getList()
      }
    })
    
  }

  const delPart = (part: any) => {
    threedApi.delPart({pid_id: part.id}).then((res: any) => {
      if(res.code === 1) {
        message.success("操作成功")
        getList()
      }
    })
  }

  

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '标准名称',
      dataIndex: 'standard_label',
      align: 'center',
    },
    {
      title: '主组',
      dataIndex: 'standard_maingroup_name',
      align: 'center',
    },
    {
      title: '分组',
      dataIndex: 'standard_subgroup_name',
      align: 'center',
    },
    {
      title: '别名',
      dataIndex: 'alias_name',
      align: 'center'
    },
    {
      title: '模型ID',
      dataIndex: 'final_id',
      align: 'center'
    },
    
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 150,
      render: (text: any, record: any, index: number) => {
        if(!writeAuth[pathname]) return null
        return (
          <div className="flex-c-c">
            <div className="flex-s-c p-color pointer mr20" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              编辑
            </div>
            {/* <div style={{ color: '#08A728' }} className="flex-s-c  pointer mlr15" onClick={() => navigate("/threed/repairdoc")}>
              <i className="iconfont icon-wendang fs14 mr2" />
              维修文档
            </div> */}
            <Popconfirm
              title="确认删除此零件吗？"
              onConfirm={() => delPart(record)}
              okButtonProps={{
                loading: record.loading ? true : false
              }}
            >
              <div style={{ color: '#FF7A00' }} className="flex-s-c  pointer " >
                <i className="iconfont icon-shanchu fs14 mr2" />
                删除
              </div>
            </Popconfirm>
            
          </div>
          
        )
      }
    },
  ]
  return (
    <div >
      <div className="flex-b-c">
        <div className='flex-s-c'>
          <span>筛选：</span>
          <Input.Search 
            className="mr12"
            style={{ width: 200 }}
            placeholder="标准名称/别名"
            onSearch={(value: any) => { pager.page = 1; setSearchData({...searchData, keyword: value}) }}
          />
          <Select
            className="mr12"
            style={{ width: 200 }}
            placeholder="主组"
            allowClear
            options={mainGroupOptions}
            value={searchData.standard_maingroup_id || undefined}
            onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, standard_maingroup_id: value, standard_subgroup_name_id: ''}) }}
          />
          <Select
            className="mr12"
            style={{ width: 200 }}
            placeholder="分组"
            allowClear
            options={searchData.standard_maingroup_id ? subGroupOptions : []}
            value={searchData.standard_subgroup_name_id || undefined}
            onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, standard_subgroup_name_id: value}) }}
          />
          
        </div>
        {
          writeAuth[pathname] ? (
            <div>
              <Button onClick={() => setCurrencyOpen(true)} className="mr15" type="primary" ghost>方位配置</Button>
              <Button onClick={() => { setOpen(true); setActiveItem({}) }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />}>添加零件</Button>
            </div>
          ): null
        }
      </div>
      <div className="mt10">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pager.page,
            total: pager.total,
            pageSize,
            showSizeChanger: true,
            onShowSizeChange: (current: any, size: any) => setPageSize(size),
            onChange: (page: any) => setPager({...pager, page}),
            showTotal: (total: number) => `共 ${total} 条`
          }}
        />
      </div>

      <Modal
        title={activeItem.id ? '编辑零件' : '添加零件'}
        open={open}
        width={460}
        onCancel={() => setOpen(false)}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className="mtb20">
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>标准名称：</label>
            <Input
              style={{ flex: 1 }}
              size="large"
              value={activeItem.standard_label}
              onChange={(e: any) => setActiveItem({...activeItem, standard_label: e.target.value})}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>主组：</label>
            <Select
              style={{ flex: 1 }}
              size="large"
              options={mainGroupOptions}
              value={activeItem.standard_maingroup_id}
              onChange={(value: any, option: any) => {
                setActiveItem({
                  ...activeItem,
                  standard_maingroup_id: option.value,
                  standard_maingroup_name: option.label,
                  standard_subgroup_name: '',
                  standard_subgroup_name_id: ''
                })
              }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>分组：</label>
            <Select
              style={{ flex: 1 }}
              size="large"
              options={activeItem.standard_maingroup_id ? otherSubGroup : []} 
              value={activeItem.standard_subgroup_name_id}
              onChange={(value, option: any) => {
                setActiveItem({
                  ...activeItem,
                  standard_subgroup_name: option.label,
                  standard_subgroup_name_id: option.value
                })
              }}
            />
          </div>
          
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>模型ID：</label>
            <Input
              style={{ flex: 1 }}
              size="large"
              value={activeItem.final_id}
              onChange={(e: any) => setActiveItem({...activeItem, final_id: e.target.value})}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red' style={{ opacity: 0 }}>* </span>别名：</label>
            <Input
              style={{ flex: 1 }}
              size="large"
              value={activeItem.alias_name}
              onChange={(e: any) => setActiveItem({...activeItem, alias_name: e.target.value})}
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="方位配置"
        width={460}
        open={currencyOpen}
        onCancel={() => setCurrencyOpen(false)}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
        footer={null}
      >
        <div className="mtb20">
          <div className='flex-b-c'>
            <div className='flex-s-c' style={{ width: '50%' }}>
              <label className="right" style={{ width: 36 }}>前：</label>
              <Input value="f" style={{ flex: 1 }} disabled />
            </div>
            <div className='flex-s-c' style={{ width: '50%' }}>
              <label className="right" style={{ width: 36 }}>后：</label>
              <Input value="b" style={{ flex: 1 }} disabled />
            </div>
          </div>
          <div className='flex-b-c mt10'>
            <div className='flex-s-c' style={{ width: '50%' }}>
              <label className="right" style={{ width: 36 }}>左：</label>
              <Input value="l" style={{ flex: 1 }} disabled />
            </div>
            <div className='flex-s-c' style={{ width: '50%' }}>
              <label className="right" style={{ width: 36 }}>右：</label>
              <Input value="r" style={{ flex: 1 }} disabled />
            </div>
          </div>
          <div className='flex-b-c mt10'>
            <div className='flex-s-c' style={{ width: '50%' }}>
              <label className="right" style={{ width: 36 }}>内：</label>
              <Input value="i" style={{ flex: 1 }} disabled />
            </div>
            <div className='flex-s-c' style={{ width: '50%' }}>
              <label className="right" style={{ width: 36 }}>外：</label>
              <Input value="o" style={{ flex: 1 }} disabled />
            </div>
          </div>

        </div>
      </Modal>

    </div>
  )
}

export default ThreedPart