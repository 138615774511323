import Chassis from './Chassis'
import Wholevehicle from './Wholevehicle'
import ThreeEngine from './Engine'
import Carbody from './Carbody'
import ThreedModel from './ThreedModel'
import Tpart from './Part'

export default ThreedModel

export {
  Chassis,
  Wholevehicle,
  ThreeEngine,
  Carbody,
  Tpart
}