import React, { useState, useEffect, useContext } from 'react'
import MainContext from '@/context/MainContext'
import { VinDetail } from '@/page/Dataedit/Vin'
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
import commonApi from '@/api/epcApi'
import { useIntl } from 'react-intl'

import css from './vin.module.scss'

interface PropsIn {
  keyword: string,
  vin?: string,
  mcid: string,
  brandcode: string,
  title?: string,
  cancelFn?: Function
}

const PartSearch: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const { keyword, mcid, vin, brandcode, cancelFn, title='' } = props
  const { store, setStore } = useContext(MainContext)
  const [ partVisible, setPartVisible ] = useState<boolean>(true)
  const [ partListVisible, setPartListVisible ] = useState<boolean>(false)
  const [ active, setActive ] = useState<any>({})
  const [ data, setData ] = useState<any>([])
  const [ total, setTotal ] = useState<any>(0)
  const [ loading, setLoading ] = useState<any>(false)
  
  useEffect(()=>{
    if(keyword){
      partSearch()
    }
  }, [keyword])
  const partSearch = () => {
    const param = {
      mcid,
      vin: vin || '',
      brandCode: brandcode,
      search_key: keyword,
      search_type: 'pid'
    }
    setLoading(true)
    commonApi.searchKey(param).then((result: {code: number, data: object[]})=>{
      let temp = JSON.parse(JSON.stringify(store.param))
      let t = 0
      setLoading(false)
      if(result.code === 1 && result.data){
        let temp: any = {}
        result.data.forEach((item: any)=>{
          if(!temp[item.mid]){
            temp[item.mid] = []
          }
          if(temp[item.mid].findIndex((it: any) => it.pid === item.pid) === -1){
            t++
            temp[item.mid].push(item)
          }
        })
        setData(temp)
        setTotal(t)
        if(result.data.length === 1){
          showList(result.data[0])
        }
      }
      if(temp.pop && t === 0){
        cancel(false)
      }
      delete temp.pop
      setStore({value: temp, type: 'param'})
      
    })
  }
  const showList = (item: object) => {
    setActive(item)
    setPartVisible(false)
    setPartListVisible(true)
  }
  const backFn = () => {
    setPartVisible(true)
    setPartListVisible(false)
  }
  const cancel = (val: boolean) => {
    setPartVisible(val)
    cancelFn && cancelFn({})
  }
  const cancelDetail = (val: boolean) => {
    setPartListVisible(val)
    cancelFn && cancelFn({})
  }
  
  return (
    <>
      <Modal
        title={(vin ? (vin + ' > ') : '') + title + keyword}
        visible={partVisible}
        cancel={cancel}
        width={1200}
      >
        <Loading visible={loading}>
          <div className={css['part-search']}>
            {
              total ? <h5>{intl.formatMessage({ id: 'foundResultNum' }, { keyword })} {intl.formatMessage({ id: 'totalNum' }, { total })}</h5> : null
            }
            {
              !loading && !total ? <div className={css.nodata}><h4>{intl.formatMessage({ id: 'notfoundCpart' }, { pid: keyword })}</h4> <button onClick={()=>cancel(false)}>{intl.formatMessage({ id: 'mainGroupQuery' })}</button></div> : null
            }
            <div className={css['content']}>
              {
                Object.keys(data).map((key: any, i: number)=>{
                  const it = data[key][0]
                  return (
                    <div className={css.bg} key={i}>
                      <h5><strong>{it.num}</strong>{` ${it.maingroupname} > `}<strong>{it.mid}</strong> {it.subgroupname}</h5>
                      {
                        data[key].map((item: any, index: number)=>{
                          return (
                            <div className={css['item']} key={index} onClick={()=>showList(item)}>
                              <div className={css['l']}>
                                <img src={item.url} alt={item.pid} />
                              </div>
                              <div className={css['r']}>
                                <h6>{item.label}</h6>
                                <p>
                                  <label>OE</label>
                                  {item.pid}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: 'mainGroup' })}</label>
                                  {item.maingroupname}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: 'subGroup' })}</label>
                                  {item.mid} {item.subgroupname}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: 'xmodel' })}</label>
                                  {item.model}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: 'remark' })}</label>
                                  {item.remark}
                                </p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Loading>
      </Modal>
      <Modal
        title={`${vin ? vin + ' > ' : ''} ${title ? (title + ' | ') : ''} ${active.num} ${active.maingroupname} > ${active.mid} ${active.subgroupname}`}
        visible={partListVisible}
        cancel={cancelDetail}
        width={'90%'}
      >
        <VinDetail
          type='partSearch'
          vin={vin}
          brandcode={active.brandCode}
          mcid={active.mcid}
          num={active.num}
          mid={active.mid}
          subgroup={active.subgroup}
          pid={active.pid}
          backFn={total > 1 ? backFn : undefined}
        />
      </Modal>
    </>
  )
}

export default PartSearch;