import React, { useState, useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import Tooltip from 'rc-tooltip'
import Toast from '@/components/Toast'
import { RndModal } from '@/components/Modal'
import Copy from '@/components/Copy'
import Upload from '@/components/Upload'
import { PartSearch } from '@/page/Dataedit/Vin'
import vinApi from '@/api/epcApi'
import styles from './vin.module.scss'
import { useIntl } from 'react-intl'

interface PropsIn {
  vin?: string,
  pid: string,
  brandCode: string,
  mcid?: string,
  mid?: string,
  num?: string,
  queryType: string
}

const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'basic':
      return {
        ...state,
        basic: {
          name: '基础信息',
          key: 'basic',
          data: action.value
        }
      };
    case 'price':
      return {
        ...state,
        price: {
          name: '4S价格',
          key: '4sPrice',
          data: action.value
        }
      };
    case 'replace':
      return {
        ...state,
        replace: {
          name: '替换件',
          key: 'interchangeableParts',
          data: action.value
        }
      };
    case 'stocks':
      return {
        ...state,
        stocks: {
          name: '在线库存',
          key: 'onlineInventory',
          data: action.value
        }
      };
    case 'articles':
      return {
        ...state,
        articles: {
          name: '知名品牌',
          key: 'famousBrand',
          data: action.value
        }
      };
    case 'imported':
      return {
        ...state,
        imported: {
          name: '进口品牌',
          key: 'importedBrand',
          data: action.value
        }
      };
    case 'applicable':
      return {
        ...state,
        applicable: {
          name: '适用车型',
          key: 'applicableVehicles',
          data: action.value
        }
      };
    default:
      return state
  }
}

let pollingIs = true
let pollingNum = 0

const PartDetail: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const { vin, pid, brandCode, mcid, mid, num, queryType } = props
  const [ tabActive, setTabActive ] = useState<string>('basic')
  const [ tabBrandActive, setTabBrandActive ] = useState<string>('All')
  const [ photo, setPhoto ] = useState<any>([])
  const [ qrcode, setQrcode ] = useState<string>('')
  const [ photoActive, setPhotoActive ] = useState<number>(0)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)

  const [ partSearchActive, setPartSearchActive ] = useState<any>({})
  const [ partSearchkey, setPartSearchkey ] = useState<any>(0)

  const [cbVisible, setCbVisible] = useState<boolean>(false)
  const [cburl, setCbUrl] = useState<string>(window.sessionStorage.getItem('cburl') || '')
  const cbname = window.sessionStorage.getItem('cbname')
  const cbicon = window.sessionStorage.getItem('cbicon')
  const [ base, setBase ] = useState<any>({})

  
  
  const [ data, setData ] = useReducer(reducer, {
    basic: {
      name: '基础信息',
      key: 'basic'
    },
    price: {
      name: '4S价格',
      key: '4sPrice'
    },
    replace: {
      name: '替换件',
      key: 'interchangeableParts'
    },
    stocks: {
      name: '在线库存',
      key: 'onlineInventory'
    },
    articles: {
      name: '知名品牌',
      key: 'famousBrand'
    },
    imported: {
      name: '进口品牌',
      key: 'importedBrand'
    },
    applicable: {
      name: '适用车型',
      key: 'applicableVehicles'
    }
  })
  useEffect(()=>{
    getBaseinfo()
    partPrice()
    replace()
    // stocks()
    articles()
    applicable('')
    pics()
    return unmount
  }, [])

  useEffect(()=>{
    if(partSearchActive.mcid){
      setPartSearchkey(partSearchkey + 1)
    }
  }, [partSearchActive.mcid])

  // useEffect(()=>{
  //   applicable(tabBrandActive)
  // }, [tabBrandActive])

  const unmount = () => {
    if(pollingNum){
      pollingIs = false
    }
  }

  const getBaseinfo = () => {
    let param = {
      maintainVinInputValue: vin || '',
      pid,
      brandCode,
      mcid,
      mid,
      num,
      query_type: queryType
    }
    vinApi.baseinfo(param).then((result: any)=>{
      if(result.code === 1){
        const d = result.data
        let baseinfo = [
          {
            key: intl.formatMessage({ id: 'originalOE' }),
            value: d.pid,
            copy: true
          },
          {
            key: intl.formatMessage({ id: 'partName' }),
            value: d.label
          },
          {
            key: intl.formatMessage({ id: 'standardName' }),
            value: d.standard_label
          },
          {
            key: intl.formatMessage({ id: 'xmodel' }),
            value: d.model,
          },
          {
            key: intl.formatMessage({ id: 'projectNumber' }),
            value: d.engpid
          },
          {
            key: intl.formatMessage({ id: 'remark' }),
            value: d.remark
          },
          // {
          //   key: 'Update time',
          //   value: d.updatetime
          // }
        ]
        let pic: any = []
        if(d.images && d.images.length && result.image_host){
          d.images.forEach((img: string)=>{
            pic.push({
              pic: `${result.image_host}/${img}`
            })
          })
        }
        setPhoto(pic)
        setBase(d)
        setData({value: baseinfo, type: 'basic'})
        // setData({value: d.price, type: 'price'})
      }
    })
  }
  
  const replace = () => {
    vinApi.replace({pid, brandCode}).then((result: any)=>{
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        setData({value: {directly, indirect}, type: 'replace'})
      }
    })
  }
  // const stocks = () => {
  //   vinApi.stocks({oe: pid, amount: 8}).then((result: any) => {
  //     if(result.code === 1 && result.data) {
  //       setData({value: result.data.stocks || [], type: 'stocks'})
  //     }
  //   })
  // }
  const partPrice = () => {
    vinApi.partPrice({pid, brandCode}).then((result: any)=>{
      if(result.code === 1){
        setData({value: result.data, type: 'price'})
      }
    })
  }
  const articles = () => {
    vinApi.articles({pid, brandCode}).then((result: any) => {
      if(result.code === 1 && result.data) {
        let tabData1: any = []
        let tabData2: any = []
        result.data.forEach((item: any) => {
          if(item.supplier_detail.regional === "FOREIGN") {
            tabData2.push(item)
          }else {
            if(item.supplier_detail && item.supplier_detail.logo_url){
              item.brand_logo = item.supplier_detail.image_host + '/' + item.supplier_detail.logo_url
            }
            if(item.images && item.images[0]){
              item.images = item.images[0]
            }
            tabData1.push(item)
          }
        })
        setData({value: tabData1 || [], type: 'articles'})
        setData({value: tabData2 || [], type: 'imported'})
      }
    })
  }
  
  const applicable = (brand: string) => {
    let param: any = {
      part: pid
    }
    if(brand && brand !== 'All'){
      param.filter_brand = brand
    }
    vinApi.applicable(param).then((result: any)=>{
      if(result.code === 1){
        result.all_brands.unshift(['All', intl.formatMessage({ id: 'all' })])
        setData({value: {title: result.all_brands, data: result.data}, type: 'applicable'})
      }
    })
  }

  const pics = () => {
    vinApi.pics({pid, brandCode}).then((result: any) => {
      if(result.code === 1 && result.data) {
        setPhoto(result.data)
      }
    })
  }
  const removePic = (e: any, item: any, index: number) => {
    e.stopPropagation()
    vinApi.removePic({
      pid: item.pid,
      pic_id: item.id
    }).then((result: any) => {
      if(result.code === 1) {
        const temp = JSON.parse(JSON.stringify(photo))
        temp.splice(index, 1)
        setPhoto(temp)
        setPhotoActive(0)
        Toast(intl.formatMessage({ id: 'deleteSuccess' }))
      }
    })
  }

  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    setUploadLoading(false)
    if(result.code === 1){
      pics()
    }
  }
  const scrollToAnchor = (id: string) => {
    if (id) {
      let anchorElement = document.getElementById(id)
      if(anchorElement) { anchorElement.scrollIntoView() }
    }
  }
  const scroll = () => {
    let content = document.getElementById('content')
    let top = content?.scrollTop
    let contop = content?.offsetTop || 0
    let active = ''
    Object.keys(data).forEach((key)=>{
      const offsetTop = document.getElementById(key)?.offsetTop || 0
      if(offsetTop && (top || top === 0) && top >= offsetTop - contop - 10){
        if(top >= offsetTop - contop - 10){
          active = key
        }
      }
    })
    setTabActive(active)
  }

  const cancelFn = () => {
    setPartSearchActive({})
    setPartSearchkey(0)
  }

  const showCb = () => {
    let url = window.sessionStorage.getItem('cburl') || ''
    url += url.includes('?') ? '&' : '?'
    const param: any = {
      pid: base.pid,
      brandcode: brandCode,
      standardLabel: base.standard_label,
      label: base.label.replace(/<[^>]*>/g, ''),
      phone: localStorage.getItem("username")
    }
    Object.keys(param).map((key)=>{
      url += `${key}=${param[key]}&`
    })
    url = url.slice(0, -1)
    setCbUrl(url)
    setCbVisible(true)
  }

  return (
    <>
      <div className={styles.partDetail}>
        <ul className={styles.tab} style={{paddingLeft: queryType === 'part_search' ? '30px' : '0'}}>
          {
            Object.keys(data).map((key, index)=>{
              const item = data[key]
              return (
                item.data && JSON.stringify(item.data) !== '[]' ? (
                  <li key={index} className={tabActive === key ? styles.active : ''} onClick={()=>scrollToAnchor(key)}>{intl.formatMessage({ id: item.key })}</li>
                ): null
              )
            })
          }
        </ul>
        <div className={styles.content} id="content" onScroll={()=>scroll()} style={{height: queryType === 'part_search' ? 'calc(100vh - 62px)' : '500px'}}>
          <div className={styles.row} id="basic">
            <div className={styles.basic}>
              {
                data.basic.data?.map((item: any, index: number)=>{
                  return (
                    item.value ? (
                      <div className={styles.item} key={index}>
                        <label>{item.key}</label>
                        <span dangerouslySetInnerHTML={{ __html: item.value }} />
                        {
                          cburl && !index ? (
                            <Tooltip mouseLeaveDelay={0} placement="top" overlay={cbname || intl.formatMessage({ id: 'xmessage' })} overlayStyle={{zIndex: 9999}}>
                              {
                                cbicon ? (
                                  <span>
                                    <img src={cbicon} style={{height: 16, cursor: 'pointer'}} onClick={()=>showCb()} />
                                  </span>
                                ): (
                                  <span className="iconfont icon-a-Group1" style={{fontSize: 14, cursor: 'pointer'}} onClick={()=>showCb()}></span>
                                )
                              }
                            </Tooltip>
                          ): null
                        }
                      </div>
                    ): null
                  )
                })
              }
            </div>
            <div className={styles.photo}>
              {
                photo.length ? (
                  <div className={styles.active}>
                    {
                      photo.length > 1 ? (
                        <>
                          <span className={styles.activeL} onClick={()=>setPhotoActive((photoActive - 1) < 0 ? photo.length - 1 : photoActive - 1)}>
                            <span className="iconfont icon-left"></span>
                          </span>
                          <span className={styles.activeR} onClick={()=>setPhotoActive((photoActive + 1) > photo.length - 1 ? 0 : photoActive + 1)}>
                            <span className="iconfont icon-right"></span>
                          </span>
                        </>
                      ) : null
                    }
                    {
                      photo[photoActive] ? (
                        <img src={photo[photoActive].pic} />
                      ) : null
                    }
                  </div>
                ): null
              }
              <div className={styles.list}>
                {/* <div style={{display: 'inline-block'}}>
                  <div className={styles.qrcode}>
                    <img src={qrcode} />
                    <div><img src={qrcode} /></div>
                  </div>
                  <p>扫码上传</p>
                </div> */}
                {
                  photo.map((item: any, index: number)=>{
                    return (
                      <div className={index === photoActive ? (`${styles.active} ${styles.item}`) : styles.item} onClick={()=>setPhotoActive(index)}>
                        {
                          item.yc_id === '007' ? <span className={styles.authority}>{intl.formatMessage({ id: 'official' })}</span> : null
                        }
                        {
                          item.is_me ? <span className={`iconfont icon-shanchu1 ${styles.removePic}`} onClick={(e)=>removePic(e, item, index)}></span> : null
                        }
                        <img src={item.pic} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {
            data.price.data && data.price.data.length ? (
              <div className={styles.box} id="price">
                <h5>{intl.formatMessage({ id: '4sPrice' })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: 'manufacturer' })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: 'explain' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'purchasePrice(excludingTax)' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'purchasePrice(includingTax)' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'salesPrice(includingTax)' })}</th>
                      <th style={{width: '17%', textAlign: 'center'}}>{intl.formatMessage({ id: 'largeInventoryPrice(includingTax)' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'updateTime' })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.price.data?.map((item: any, index: number)=>{
                        return (
                          <tr>
                            <td>{item.mill}</td>
                            <td>{item.remark}</td>
                            <td>{item.eot_price}</td>
                            <td>{item.cost_price}</td>
                            <td>{item.sale_price}</td>
                            <td>-</td>
                            <td>{item.vaild_date}</td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            ): null
          }
          {
            data.replace?.data?.directly.length ? (
              <div className={styles.box} id="replace">
                <h5>{intl.formatMessage({ id: 'interchangeableParts' })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: '品牌' })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: 'OE号' })}</th>
                      <th style={{width: '20%', textAlign: 'center'}}>{intl.formatMessage({ id: '零件名称' })}</th>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: '件数' })}</th>
                      <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: '备注' })}</th>
                      <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: '销售价' })}</th>
                    </tr>
                  </thead>
                </table>
                {
                  data.replace?.data?.directly.length ? (
                    <table>
                      <caption>{intl.formatMessage({ id: 'directReplacement' })}</caption>
                      <tbody>
                        {
                          data.replace.data.directly.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>
                                  {/* {item.brandcn} */}
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.brandcn}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} /></p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i:number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
                {
                  data.replace?.data?.indirect.length ? (
                    <table>
                      <caption>{intl.formatMessage({ id: 'indirectReplacement' })}</caption>
                      <tbody>
                        {
                          data.replace.data.indirect.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} /></p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
              </div>
            ): null
          }
          {
            data.stocks.data?.length ? (
              <div className={styles.box} id="stocks">
                <h5>{intl.formatMessage({ id: 'onlineInventory' })}</h5>
                <div className={styles.stocks}>
                  {
                    data.stocks.data?.map((item: any)=>{
                      return (
                        <div className={styles.item}>
                          <div className={styles.flex}>
                            <h6>{item.quality_name}</h6>
                            <em>{item.sale_price ? '￥' : ''}{item.sale_price}</em>
                          </div>
                          <div className={styles.flex}>
                            <p style={{width: '60%'}}>{item.company_name}</p>
                            <p>
                              {item.city.length > 4 ? item.city.substr(0, 4) : item.city}{item.city.length > 4 ? '…' : ''}
                            </p>
                            {/* <span className="zhb-update-time">{item.update_time}</span> */}
                          </div>
                          <div className={styles.info}>
                            {
                              item.company_name ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: 'company' })}：</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.company_name}</span>
                              </div> : null
                            }
                            {
                              item.province || item.city || item.area ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: 'address' })}：</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.province}{item.city}{item.area}</span>
                              </div> : null
                            }
                            {
                              item.phone ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: 'phone' })}：</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.phone}</span>
                              </div> : null
                            }
                            {
                              item.wechat ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: 'wechat' })}：</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.wechat}</span>
                              </div> : null
                            }
                            {
                              item.qq ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>Q&nbsp;&nbsp;Q：</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.qq}</span>
                              </div> : null
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ): null
          }
          {
            data.articles.data?.length ? (
              <div className={styles.box} id="articles">
                <h5>{intl.formatMessage({ id: 'famousBrand' })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: 'picture' })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: 'brandNumber' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'partName' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'brand' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'xmodel' })}</th>
                      <th style={{width: '17%', textAlign: 'center'}}>{intl.formatMessage({ id: 'notes' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'referencePrice' })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.articles.data?.map((item: any, index: number)=>{
                        return (
                          <tr>
                            <td>
                              {
                                item.images ? (
                                  <img src={ item.images } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.normalize_article_number}</td>
                            <td>{item.label}</td>
                            <td>
                              {
                                item.brand_logo ? (
                                  <img src={ item.brand_logo } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.Status}</td>
                            <td>{item.description}</td>
                            <td>{item.price}</td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            ): null
          }
          {
            data.imported.data?.length ? (
              <div className={styles.box} id="imported">
                <h5>{intl.formatMessage({ id: 'importedBrand' })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: 'picture' })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: 'brandNumber' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'partName' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'brand' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'xmodel' })}</th>
                      <th style={{width: '17%', textAlign: 'center'}}>{intl.formatMessage({ id: 'notes' })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: 'referencePrice' })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.imported.data?.map((item: any, index: number)=>{
                        return (
                          <tr>
                            <td>
                              {
                                item.images ? (
                                  <img src={ item.images } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.normalize_article_number}</td>
                            <td>{item.label}</td>
                            <td>
                              {
                                item.brand_logo ? (
                                  <img src={ item.brand_logo } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.Status}</td>
                            <td>{item.description}</td>
                            <td>{item.price}</td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            ): null
          }
          {
            data.applicable.data?.data?.length ? (
              <div className={styles.box} id="applicable">
                <h5>{intl.formatMessage({ id: 'applicableVehicles' })}</h5>
                <div className={styles.tabBrand}>
                  <h6>{intl.formatMessage({ id: 'brand' })}</h6>
                  <ul>
                    {
                      data.applicable.data?.title.map((item: any, index: number)=>{
                        return (
                          <li key={index} onClick={()=>setTabBrandActive(item[0])} className={tabBrandActive === item[0] ? styles.active : ''}>{item[1]}</li>
                        )
                      })
                    }
                  </ul>
                </div>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '15%'}}>{intl.formatMessage({ id: 'brand' })}</th>
                      <th style={{width: '35%'}}>{intl.formatMessage({ id: 'model' })}</th>
                      <th style={{width: '15%'}}>{intl.formatMessage({ id: 'yearf' })}</th>
                      <th style={{width: '20%'}}>{intl.formatMessage({ id: 'market' })}</th>
                      <th style={{width: '15%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.applicable.data?.data.map((item: any, index: number)=>{
                        return (
                          tabBrandActive === 'All' || tabBrandActive === item.uri_param.brandCode ? (
                            <tr key={index}>
                              <td>{item.brandname}</td>
                              <td>{item.cars_model}</td>
                              <td>{item.year}</td>
                              <td>{item.market}</td>
                              <td>
                                {
                                  queryType === 'part_search' ? (
                                    <button className={styles.but} onClick={()=>setPartSearchActive(item.uri_param)}>{intl.formatMessage({ id: 'see' })}</button>
                                  ): null
                                }
                              </td>
                            </tr>
                          ): null
                          
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            ): null
          }
        </div>
      </div>
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partSearchActive.p}
            mcid={partSearchActive.mcid}
            brandcode={partSearchActive.brandCode}
            title={partSearchActive.title}
            cancelFn={cancelFn}
          />
        ): null
      }
      <RndModal
        title={base.pid}
        visible={cbVisible}
        cancel={() => setCbVisible(false)}
      >
        <iframe src={cburl} style={{width: '100%', height: 'calc(100% - 40px)', border: 'none'}} ></iframe>
      </RndModal>
    </>
  )
}

export default PartDetail