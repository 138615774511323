import React, { Fragment, useState, useMemo } from 'react'
import { Upload, Spin, message } from 'antd'


const UploadModel: React.FC<any> = (props) => {
  const { item, width = 230, disabled = false, uploadLabel, lkey } = props
  const [change, setChange] = useState<boolean>(false)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)

  const fileName = useMemo(() => {
    let url = item[lkey]
    if(url) {
      let urlSplitArr = url.split("/")
      return urlSplitArr[urlSplitArr.length - 1]
    }
    return ''
  },[item[lkey]])

  const onChange = ({file}: any) => {
    let status: string = file.status
    if(status === "uploading") setUploadLoading(true)
    if(["error", "done"].includes(status)) setUploadLoading(false)
    if(status === "done") {
      let res = file.response
      if(res.code === 1) {
        let url = res.data.img_url
        item[lkey] = url
        setChange(change => !change)
      }
    }else if(status === "error") {
      message.error("上传失败")
    }
    console.log(file)
  }
  return (
    <Fragment>
      {
        item[lkey] ? (
          <div className='flex-b-c bgc-f8 plr10 br4' style={{ height: 40, width, opacity: disabled ? .5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}>
            <div>{fileName}</div>
            <Upload
              name="file"
              action="/v1/operate/common/upload"
              showUploadList={false}
              onChange={onChange}
              disabled={disabled}
            >
              <Spin spinning={uploadLoading}>
                <div className="p-color pointer">重新上传</div>
              </Spin>
            </Upload>
          </div>
        ): (
          <Upload
            name="file"
            action="/v1/operate/common/upload"
            showUploadList={false}
            onChange={onChange}
            disabled={disabled}
          >
            <Spin spinning={uploadLoading}>
              <div className='flex-c-c p-color p-b-color br4 pointer' style={{ height: 40, width, opacity: disabled ? .5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}>
                {uploadLabel || '上传文件'}
              </div>
            </Spin>
          </Upload>
        )
      }
    </Fragment>
  )
}

export default UploadModel