import React, { Fragment, useEffect, useState, useContext } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, Tabs, message, Popover } from 'antd'
import type { TabsProps } from 'antd'
import Datatopbox from './Components/Datatopbox'
import UploadModel from '@/components/UploadModel'
import { downloadFile } from '@/utils/utils'
import ThreedPart from './Components/ThreedPart'
import threedApi from '@/api/threedApi'
import Lookthreed from './Components/Lookthreed'
import context from '@/context/MainContext'
import { useLocation } from 'react-router-dom'



const Chassis: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { store, setStore } = useContext(context)
  const { writeAuth } = store
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false)
  const [threedOpen, setThreedOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [ispreview, setIspreview] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  const [currencyData, setCurrencyData] = useState<any>({models: {}})
  const [currencyType, setCurrencyType] = useState<'oil_car_normal' | 'electric_car_normal'>("oil_car_normal")
  
  
  const [searchData, setSearchData] = useState<any>({
    front: '',
    rear: ''
  })

  const [activeKey, setActiveKey] = useState<'model' | 'part'>("model")

  
  
  useEffect(() => {
    getList()
  },[searchData.front, searchData.rear, pager, pageSize])

  useEffect(() => {
    if(currencyOpen) {
      let params = {
        front: currencyType,
        rear: currencyType,
      }
      threedApi.getChassisList(params).then((res: any) => {
        if(res.code === 1) {
          let _data = res.data.records && res.data.records[0] ? res.data.records[0] : {models: {}, front: currencyType, rear: currencyType, drive_mode: currencyType}
          _data.fuel = _data.fuel_type
          if(_data.models && typeof(_data.models) === 'string') {
            _data.models = JSON.parse(_data.models)
          }
          setCurrencyData(_data)
        }
      })
    }
  },[currencyOpen])

  const getList = () => {
    let params = {
      front: searchData.front,
      rear: searchData.rear,
      page: pager.page,
      size: pageSize
    }
    setLoading(true)
    threedApi.getChassisList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        setList(res.data.records ? res.data.records : [])
      }
    })
  }

  const editItem = (item: any) => {
    setActiveItem({...item, fuel: item.fuel_type})
    setOpen(true)
  }
  const closeOpen = () => {
    setActiveItem({})
    setOpen(false)
  }

  const preview = (item: any) => {
    setActiveItem({...item, fuel: item.fuel_type})
    setThreedOpen(true)
  }
  const downLoad = (url: any) => {
    downloadFile(url)
  }

  const submit = (type?: string) => {
    let params: any = {}
    if(type) {
      params = {...currencyData}
    }else {
      params = {...activeItem}
      if(!(params.fuel && params.front && params.rear && params.drive_mode && (params.models.android || params.models.ios))) {
        message.warning("请填写必填项！")
        return false
      }
    }
    if(params.id) {
      params.mongo_id = params.id
      delete params.id
    }
    setSubmitLoading(true)
    threedApi.operateChassisItem(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setCurrencyOpen(false)
        closeOpen()
        message.success("操作成功")
        getList()
      }
    })
  }

  const submitCurrency = () => {
    submit('currency')
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '燃油方式',
      dataIndex: 'fuel_type',
      align: 'center',
    },
    {
      title: '驱动方式',
      dataIndex: 'drive_mode',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        let other = [{label: '前驱', value: 'front_drive'},{label: '后驱', value: 'rear_drive'},{label: '四驱', value: 'four_wheel_drive'}]
        let i = other.findIndex((it: any) => it.value === text)
        if(i === -1) return '-'
        return (
          <div>
            {other[i].label}
          </div>
        )
      }
    },
    {
      title: '前悬架',
      dataIndex: 'front',
      align: 'center'
    },
    {
      title: '后悬架',
      dataIndex: 'rear',
      align: 'center'
    },
    // {
    //   title: '示例车架号',
    //   dataIndex: 'vin',
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => {
    //     return (
    //       <div className="flex-c-c p-color">
    //         {text}
    //       </div>
    //     )
    //   }
    // },
    // {
    //   title: '大小 (MB)',
    //   dataIndex: 'id',
    //   align: 'center'
    // },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 220,
      render: (text: any, record: any, index: number) => {
        if(!writeAuth[pathname]) return null
        let models = record.models ? record.models : {}
        let notclick = !models.android || (models.android && !models.android.includes('.gltf')) ? true : false
        return (
          <div className="flex-c-c">
            <div className={"flex-s-c p-color pointer" + (notclick ? " no-click" : '')} onClick={() => !notclick && preview(record)}>
              <i className="iconfont icon-yulan fs14 mr2" />
              预览
            </div>
            {
              models.ios || models.android ? (
                <Popover
                  content={
                    <div>
                      {
                        models['ios'] ? (
                          <div className='pointer ptb5' onClick={() => downLoad(models['ios'])}>ios</div>
                        ): null
                      }
                      {
                        models['android'] ? (
                          <div className='pointer ptb5' onClick={() => downLoad(models['android'])}>android</div>
                        ): null
                      }
                    </div>
                  }
                >
                  <div className="flex-s-c p-color pointer mlr15">
                    <i className="iconfont icon-xiazai fs14 mr2" />
                    下载
                  </div>
                </Popover>
              ): (
                <div className="flex-s-c p-color pointer mlr15 no-click" >
                  <i className="iconfont icon-xiazai fs14 mr2" />
                  下载
                </div>
              )
            }
            <div className="flex-s-c p-color pointer" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              修改
            </div>
          </div>
          
        )
      }
    },
  ]

  const items: TabsProps['items'] = [
    {
      key: 'model',
      label: '模型',
      children: (
        <Fragment>
          <div className="flex-b-c">
            <div className='flex-s-c'>
              <span>筛选：</span>
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="前悬架"
                options={['麦弗逊', '双叉臂', '多连杆', '空气减震'].map((it: any) => {
                  return {
                    label: it,
                    value: it
                  }
                })}
                allowClear
                value={searchData.front || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, front: value}) }}
              />
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="后悬架"
                options={['多连杆', '扭力梁', '整体桥', '双叉臂', '空气减震'].map((it: any) => {
                  return {
                    label: it,
                    value: it
                  }
                })}
                allowClear
                value={searchData.rear || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, rear: value}) }}
              />
            </div>
            {
              writeAuth[pathname] ? (
                <div>
                  <Button onClick={() => {setCurrencyOpen(true); setCurrencyType("oil_car_normal")}} className="mr15" type="primary" ghost>通用模型(油车)</Button>
                  <Button onClick={() => {setCurrencyOpen(true); setCurrencyType("electric_car_normal")}} className="mr15" type="primary" ghost>通用模型(电车)</Button>
                  <Button onClick={() => { setOpen(true); setActiveItem({models: {}}) }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />}>添加模型</Button>
                </div>
              ): null
            }
          </div>
          <div className="mt10">
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                current: pager.page,
                total: pager.total,
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current: any, size: any) => setPageSize(size),
                onChange: (page: any) => setPager({...pager, page}),
                showTotal: (total: number) => `共 ${total} 条`
              }}
            />
          </div>
        </Fragment>
      )
    },
    // {
    //   key: 'part',
    //   label: '零件',
    //   children: <ThreedPart/>
    // },
  ]
  return (
    <div >
      <Datatopbox

      />
      <div className="mt8 bgc-fff plr15 pb10 br4" style={{ minHeight: 'calc(100vh - 140px)' }}>
        <Tabs
          items={items}
          activeKey={activeKey}
          onChange={(value: any) => setActiveKey(value)}
        />
      </div>

      <Modal
        title={activeItem.id ? '修改模型' : "添加模型"}
        open={open}
        width={620}
        onCancel={() => closeOpen()}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
        maskClosable={false}
      >
        <div className="mtb20">
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>燃油方式：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择燃油方式"
              size="large"
              options={['汽油', '纯电', '混动', '柴油'].map((it: string) => {
                return {
                  label: it,
                  value: it
                }
              })}
              value={activeItem.fuel || undefined}
              onChange={(value: any) => { setActiveItem({...activeItem, fuel: value}) }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>驱动方式：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择驱动方式"
              size="large"
              options={[{label: '前驱', value: 'front_drive'},{label: '后驱', value: 'rear_drive'},{label: '四驱', value: 'four_wheel_drive'}]}
              value={activeItem.drive_mode || undefined}
              onChange={(value: any) => { setActiveItem({...activeItem, drive_mode: value}) }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>前悬架：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择前悬架"
              size="large"
              options={['麦弗逊', '双叉臂', '多连杆', '空气减震'].map((it: any) => {
                return {
                  label: it,
                  value: it
                }
              })}
              value={activeItem.front || undefined}
              onChange={(value: any) => { setActiveItem({...activeItem, front: value}) }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>后悬架：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择后悬架"
              size="large"
              options={['多连杆', '扭力梁', '整体桥', '双叉臂', '空气减震'].map((it: any) => {
                return {
                  label: it,
                  value: it
                }
              })}
              value={activeItem.rear || undefined}
              onChange={(value: any) => { setActiveItem({...activeItem, rear: value}) }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>模型：</label>
            <div className='flex-1 flex-b-c'>
              <UploadModel
                item={activeItem.models}
                lkey="ios"
                uploadLabel="上传IOS文件(加密zip)"
              />
              <UploadModel
                item={activeItem.models}
                lkey="android"
                uploadLabel="上传安卓文件(Gltf)"
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={`通用模型(${currencyType === 'oil_car_normal' ? '油车' : '电车'})`}
        width={620}
        open={currencyOpen}
        onCancel={() => setCurrencyOpen(false)}
        onOk={() => submitCurrency()}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
        maskClosable={false}
      >
        <div className="mtb20">
          <div className='flex-s-c'>
            <label style={{ width: 100 }}>通用底盘：</label>
            <div className='flex-1 flex-b-c'>
              <UploadModel
                item={currencyData.models}
                lkey="ios"
                uploadLabel="上传IOS文件(加密zip)"
              />
              <UploadModel
                item={currencyData.models}
                lkey="android"
                uploadLabel="上传安卓文件(Gltf)"
              />
            </div>
          </div>
        </div>
      </Modal>

      {
        threedOpen ? (
          <Lookthreed
            title='预览'
            open={threedOpen}
            setOpen={(value: any) => setThreedOpen(value)}
            file={activeItem.models && activeItem.models.android ? activeItem.models.android : "https://bigfiles.007vin.com/3d/07114_Web_v2.gltf"}
          />
        ): null
      }

    </div>
  )
}

export default Chassis