import React, { useState } from "react";
import { Input } from 'antd'
import { Outlet } from 'react-router-dom'


const Vincon: React.FC<any> = (props) => {
  const {  } = props
  const [vin, setVin] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const search = () => {
    
  }
  return (
    <div className="bgc-fff p10">
      <Input.Search 
        placeholder="请输入VIN号"
        size="large"
        allowClear
        value={vin} 
        onChange={(e: any) => {
          let _vin: string = e.target.value
          setVin(_vin ? _vin.toUpperCase() : '')
        }} 
        enterButton="车架号查询"
        loading={loading}
        onSearch={() => search()}
      />
      <Outlet/>
    </div>
  )
}

export default Vincon