//手机号格式check
export const checkMobile = (mobile: any) => {
  let mobileTest = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if(mobileTest.test(mobile)) return true
  return false
}

//下载文件
export const downloadFile = (url: any) => {
  let a: any = document.createElement("a")
  a.setAttribute("download", true)
  a.href = url
  a.click()
  a.remove()
}

export function clearAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const getSearchParams = (search: any) => {
	let _search = search.slice(1)
	let _searchObj: any = {}
	_search.split("&").forEach((item: any) => {
	  let _itemArr = item.split("=")
	  _searchObj[_itemArr[0]] = decodeURIComponent(_itemArr[1])
	})
	return _searchObj
}

export const cdnsPath = 'https://cdns.007vin.com'
export const cdnsImgPath = 'https://structimgs.007vin.com'

export const getUrlParams = () => {
  let theRequest: any = {}
  const search = window.location.search
  if (search.indexOf("?") !== -1){
    let str = search.substr(1)
    let strs = str.split("&")
    for(var i = 0; i < strs.length; i ++) {
      theRequest[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1])
    }
  }
  return theRequest
}

export const compress = (img: any) => {
  let canvas = document.createElement('canvas')
  let ctx: any = canvas.getContext('2d')
  let width = img.width
  let height = img.height
  canvas.width = width
  canvas.height = height
  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(img, 0, 0, width, height)
  let ndata = canvas.toDataURL('image/jpeg', 0.5)
  return ndata
}
export const dataURItoBlob = (base64Data: string) => {
  var byteString
  if (base64Data.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(base64Data.split(',')[1])
  } else {
    byteString = unescape(base64Data.split(',')[1])
  }
  var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}