import React, { useState, useReducer, useEffect, useContext } from 'react'
import { Breadcrumb, Popover, Modal, Input, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import mainScss from './main.module.scss'
import context from '@/context/MainContext'
import accountApi from '@/api/accountApi'
import { clearAllCookies } from '@/utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSearchParams } from '@/utils/utils'



const Header: React.FC = () => {
  const navigate = useNavigate()
  const { store } = useContext(context)
  const { userInfo, menuData } = store

  const [newPwd, setNewPwd] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  console.log(userInfo)
  

  const logout = () => {
    window.localStorage.removeItem("token")
    clearAllCookies()
    navigate("/login")
  }


  const updatePwd = () => {
    let params = {
      edit_id: userInfo.uid,
      username: userInfo.username,
      nickname: userInfo.nickname,
      password: newPwd
    }
    if(!newPwd.trim().length) {
      message.warning("请输入新密码！")
      return false
    }
    setSubmitLoading(true)
    accountApi.updateUser(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("修改成功，请重新登录！")
        setOpen(false)
        logout()
      }
    })
  }
  

  return (
    <div className={mainScss.header} >
      <div className={mainScss.logo}><img src='/admin/image/logo.png' /></div>
      <div className={mainScss.breadcrumb}>
        <MyBreadcrumb
          menuList={menuData}
        />
      </div>
      <Popover
        content={
          <div>
            <div className="flex-s-c pointer" onClick={() => { setNewPwd(""); setOpen(true); }}><i className="iconfont icon-xiugaimima mr5" />修改密码</div>
            <div onClick={() => logout()} className="flex-s-c pointer mt10"><i className="iconfont icon-tuichudenglu mr5" />退出登录</div>
          </div>
        }
      >
        <div className={mainScss.user}>
          <img  />
          {/* <span className="iconfont icon-zhanghao" style={{fontSize: 12}}></span> */}
          <em>{userInfo.nickname || userInfo.username}</em>
          <DownOutlined style={{color: '#888888'}} />
        </div>
      </Popover>

      <Modal
        title="修改密码"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => updatePwd()}
        okButtonProps={{
          loading: submitLoading
        }}
      >
        <div className="mtb20">
          <div className="flex-s-c">
            <label className="right" style={{ width: 80 }}>账号：</label>
            <Input 
              style={{ flex: 1 }}
              value={userInfo.username}
              disabled
            />
          </div>
          <div className="flex-s-c mt15">
            <label className="right" style={{ width: 80 }}>新密码：</label>
            <Input.Password
              style={{ flex: 1 }}
              value={newPwd}
              onChange={(e: any) => setNewPwd(e.target.value)}
            />
          </div>
        </div>
      </Modal>

      
    </div>
  )
}


const MyBreadcrumb: React.FC<any> = (props) => {
  const navigate = useNavigate()
  const { menuList } = props
  const { pathname, search } = useLocation()
  const [ breadcrumbList, setBreadcrumbList ] = useState<any>([])

  useEffect(() => {
    let _breadcrumbList: any = getBreadcrumbs()
    setBreadcrumbList(_breadcrumbList)
  },[pathname, search])

  const getBreadcrumbs = () => {
    let matches: any = []
    const { navname, brandCode } = getSearchParams(search)
    let item = menuList.find((it: any) => it.key === pathname)
    if(pathname === '/datastandard/model/detail') {
      matches.push({
        label: '车型',
        key: `/datastandard/model?brandCode=${brandCode}`
      },{
        label: `${navname}`,
        key: '/datastandard/model/detail'
      })
    }else if(item) {
      matches.push(item)
    }
    return matches
  }
  return (
    <Breadcrumb>
      {
        breadcrumbList.map((item: any, index: number) => {
          return (
            <Breadcrumb.Item key={index} onClick={() => navigate(item.key)}>{item.label}</Breadcrumb.Item>
          )
        })
      }
    </Breadcrumb>
  )
}

export default Header