import { message, Select, Spin } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'



let indexObj: any = {}

const headers = [
  {label: '品牌', key: 'brandCode'}, 
  {label: '型号', key: 'engine_model'}, 
  {label: '燃油方式', key: 'fuel_type'}, 
  {label: '排量', key: 'displacement'}, 
  {label: '进气形式', key: 'air_intake'}, 
  {label: '气缸数', key: 'cylinders_num'}, 
  {label: '布局', key: 'engine_layout'}, 
  {label: '最大马力', key: 'ps'}, 
  {label: '最大扭矩', key: 'nm'}, 
  {label: '', key: 'id'}
]

let validations = {}

const EngineExcel: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { brandData, writeAuth } = store
  const { pathname } = useLocation()

  const [brandsList, setBrandsList] = useState<any>([])

  const [brandCode, setBrandCode] = useState<string>('audi')
  const [loading, setLoading] = useState<boolean>(false)

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})


  useEffect(() => {
    initExcel()
  },[])

  useEffect(() => {
    getList()
  },[brandCode])

  useEffect(() => {
    let _brandList = Object.keys(brandData).map((key: string) => {
      return {
        label: brandData[key].name,
        value: key
      }
    })
    setBrandsList(_brandList)
  },[brandData])

  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: headers.length,
        width: 150
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',cellEdited)

    // jexcelRef.current.on('cell-selected', cellSelected)
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    // jexcelRef.current.on('cell-edited', (e: any) => console.log(e))
    
    jexcelRef.current.on("select-click", (data: any, ri: any, ci: any) => {
      // console.log(data, ri, ci)
      if(!ri) return false
      let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      rowData = Object.values(rowData)
      console.log(rowData)
      let params: any = {
      }
      let keys: any = Object.keys(indexObj)
      rowData.forEach((it: any, i: number) => {
        let key = keys[i]
        if(i !== ci) {
          params[key] = it.text
        } else {
          params[key] = data
        }
        
      })
      params['brandCode'] = brandCode
      if(params.id) {
        params.edit_id = params.id
        delete params.id
      }
      
      updateItems([params])
    })
    // 编辑输入
    jexcelRef.current.on("cell-blur", (text: any, ri: any, ci: any) => {
      if(!ri) return false
      // console.log(text, ri, ci)
      if(ci === indexObj['ps'] || ci === indexObj['nm']) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        rowData = Object.values(rowData)
        console.log(rowData)
        let params: any = {
        }
        let keys: any = Object.keys(indexObj)
        rowData.forEach((it: any, i: number) => {
          let key = keys[i]
          params[key] = it.text
        })
        params['brandCode'] = brandCode
        if(params.id) {
          params.edit_id = params.id
          delete params.id
        }
        updateItems([params])
        console.log(params)
      }
    })
    
    jexcelRef.current.validate()
    
    // getList()
  }
  const initData = async (list: any) => {
    let _rows: any = { len: list.length + 100 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let selectStrs = ['fuel_type', 'engine_model', 'engine_layout', 'displacement', 'cylinders_num', 'air_intake']
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        if(item.key === 'tax_money') {
          cells.push({
            text: items['money'] - items['tax'] ? items['money'] - items['tax'] : '',
            editable: false
          })
        }else if(item.key === 'refund_num') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 1
          })
        }else if(item.key === 'refund_money') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 1
          })
        }else if(item.key === 'settled_money') {
          if(items['refund_money']) {
            cells.push({
              text: items[item.key] ? items[item.key] : '',
              editable: false,
              style: 1
            })
          }else {
            cells.push({
              text: items[item.key] ? items[item.key] : '',
              editable: false
            })
          }
        }else if(item.key === 'unsettled_money') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 2
          })
        }else if(selectStrs.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true,
            style: 2
          })
        }else if(item.key === 'brandCode') {
          let key = items[item.key]
          cells.push({
            text: key && brandData[key] ? brandData[key].name : '',
            editable: false,
            style: 0
          })
        }else if(item.key === 'id') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
      })
      _rows[j + 1] = {
        cells
      }
    })
    
    let other: any = 'engine,fuel,dis,air,cylinder,layout'.split(',')
    let _validations: any = {}
    let promises = await Promise.all(other.map((key: string) => {
      return dataApi.getEngineSelectOptions({brandCode, search_type: key})
    }))

    promises.forEach((res: any, index: number) => {
      let value = res.data.join(",")
      let firstWord = "B"
      if(index === 1) {
        firstWord = "C"
      }else if(index === 2) {
        firstWord = "D"
      }else if(index === 3) {
        firstWord = "E"
      }else if(index === 4) {
        firstWord = "F"
      }else if(index === 5) {
        firstWord = "G"
      }
      _validations[index] = {
        "refs": Array.from({ length: list.length + 100 }, (_, index) => firstWord + (index + 2)),
        "mode": "cell",
        "type": "list",
        "required": false,
        "operator": "be",
        "value": value
      }
    })
    console.log(Object.values(_validations))
    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'B1:O1'
      },
      cols: {
        9: {
          hide: true
        }
      },
      validations: Object.values(_validations),
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }
  const getList = () => {
    let params = {
      brandCode
    }
    setLoading(true)
    dataApi.getAllEngineList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        initData(res.data.records)
      }
    })
  }

  const updateItems = (list: any) => {
    if(!writeAuth[pathname]) {
      message.warning("暂不支持编辑操作！")
      return false
    }
    let params = {
      edit_list: list
    }
    dataApi.updateItems(params).then((res: any) => {
      if(res.code === 1) {
        message.success("操作成功")
      }
    })
  }

  return (
    <div className='p10 bgc-fff' >
      <div className='flex-s-c'>
      <span>筛选：</span>
        <Select
          className="mr12"
          style={{ width: 170 }}
          placeholder="品牌"
          showSearch
          filterOption={(input: any, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={brandsList}
          value={brandCode || undefined}
          onChange={(value: any) => setBrandCode(value)}
        />
      </div>
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
    </div>
  )
}

export default EngineExcel